/*--------------------------------------------------------------------------
   l-wrap
---------------------------------------------------------------------------*/
.l-wrap {
	position: relative;
	width: $min-width-pc;
	margin: 0 auto;
	padding: 0 12px;
}



/* media query -> sp
=================================================================*/
@media #{$bp-sp} {
	.l-wrap {
		width: auto;
		padding: 0 35px;
	}
}


/* media query -> sp-min
=================================================================*/
@media #{$bp-sp-min} {
	.l-wrap {
		width: auto;
		padding: 0 15px;
	}
}
