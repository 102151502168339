/*--------------------------------------------------------------------------
   m-inq-tel
---------------------------------------------------------------------------*/
.m-inq-tel {
	position: relative;
	margin-top: 100px;
	padding: 37px 0;
	background-color: #F7F7F7;
	text-align: center;

	@include mq-sp {
		margin-top: 50px;
		padding: 25px 0;
	}
	
	&__hdg {

		@include mq-sp {
			font-size: 14px;
		}
	}
	
	&__number {
		font-size: 40px;
		font-weight: 700;
		line-height: 1.4;

		@include mq-sp {
			font-size: 28px;
		}
		
		a {
			cursor: text;
		}
	}
	
	&__time {

		@include mq-sp {
			font-size: 14px;
		}
	}
}
