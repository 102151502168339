/*--------------------------------------------------------------------------
   m-error
---------------------------------------------------------------------------*/
.m-error {
	margin-bottom: 40px;
	padding: 20px 35px;
	border: 2px solid #fb4040;
	background: #ffe4e4;
	color: #fb4040;

	@include mq-sp {
		padding: 10px 20px;
	}

	&__alert {
		margin-bottom: .5em;
		font-size: 18px;
		line-height: 1.6;

		@include mq-sp {
			font-size: 16px;
		}
	}

	&__txt {
		margin: 10px 0 0 15px;
		line-height: 1.8;

		li {
			margin-top: 5px;
			list-style: disc;
		}
	}
}
