/*--------------------------------------------------------------------------
   .g-header
---------------------------------------------------------------------------*/
.g-header {
	position: absolute;
  top: 0;
  left: 0;
  min-width: 1200px;
	width: 100%;
  background-color: rgba(#fff, 1);
  z-index: 99;

  @include mq-sp {
		min-width: $min-width-sp;
    width: 100%;
  }

  &.is-fixed {
    position: fixed;
    box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0);
    transform: translateY(-110%);
  }

  &.is-show {
    box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.1);
    transition: box-shadow, transform 0.6s $ease-out-circ;
    transform: translateY(0);
  }

  &.is-hide {
    box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0);
    transition: box-shadow, transform 0.6s $ease-out-circ;
    transform: translateY(-110%);
  }

	.l-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: auto;
		height: 122px;
		padding: 0 50px;

		@include mq-sp {
			padding: 0;
			height: 90px;
		}
	}

	h1.g-header__logo {
		display: none;
	}

	&__logo {
		position: relative;
		z-index: 999;
		line-height: 1;

		@include mq-sp {
			margin-left: 34px;

			img {
				width: 120px;
			}
		}

		a {
			display: block;
		}
	}

	&__menubtn {
		display: none;

		@include mq-sp {
			display: block;
			position: absolute;
			right: 0;
			top: 0;
			width: 90px;
			height: 90px;
			// background-color: #fff;
			cursor: pointer;
			z-index: 999;

			.lines {
				display: block;
				position: absolute;
				top: 32px;
				left: 26px;
				overflow: hidden;
				margin: 0;
				padding: 0;
				width: 39px;
				height: 26px;
				font-size: 0;
				text-indent: -9999px;
				appearance: none;
				box-shadow: none;
				border-radius: none;
				border: none;

				span {
					display: inline-block;
					position: absolute;
					left: 0;
					width: 100%;
					height: 2px;
					transition: all .4s;
					box-sizing: border-box;
					background-color: #909090;

					&:nth-of-type(1) {
						top: 0;
						animation: menu-bar01 .75s forwards;
					}
					&:nth-of-type(2) {
						top: 12px;
						transition: all .25s linear;
						opacity: 1;
					}
					&:nth-of-type(3) {
						bottom: 0;
						animation: menu-bar02 .75s forwards;
					}
				}
				&:focus {
					outline: none;
				}
			}
		}
	}

	&__wrap {
		display: flex;
		align-items: center;

		@include mq-sp {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			padding: 110px 35px 40px;
			overflow-y: scroll;
			background-color: #fff;
			pointer-events: none;
			visibility: hidden;
			opacity: 0;
		}
	}

	&__gnav {
		display: flex;

		@include mq-sp {
			display: block;
		}
	}

	&__sp-tel {
		@include mq-sp {
			margin-top: 50px;
			text-align: center;

			.number {
				font-size: 26px;
				font-weight: 700;
			}

			.time {
				margin-top: 5px;
				font-size: 15px;
			}
		}
	}

	.sns-nav {
		display: flex;
		margin-left: 40px;

		@include mq-sp {
			margin: 40px 0 0;
			justify-content: center;
		}

		&__item {
			line-height: 1;

			@include mq-sp {
				width: 26px;
				height: 26px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			a {
				display: block;
				transition: .2s;

        .mode-pc &:hover img {
          animation: t_bounce 2s ease-in-out;

          @keyframes t_bounce {
            5%  { transform: scale(1.2, .8); }
            10% { transform: scale(.8, 1.2) translateY(-2px); }
            15% { transform: scale(1, 1); }
          }
        }
			}

			& + .sns-nav__item {
				margin-left: 13px;

				@include mq-sp {
					margin-left: 20px;
				}
			}
		}
	}
}

/* gnav */
.g-header .gnav {
	display: flex;

	@include mq-sp {
		display: block;
	}

	&__item {
		margin-left: 18px;
		font-family: $font-family-en;
    font-size: 17px;
    // font-weight: 700;

		@include mq-sp {
			margin: 0 0 12px 0;
			font-size: 24px;
			text-align: center;
		}

		a {
			display: block;
      position: relative;
			padding: 0 2px;

			@include mq-sp {
				display: block;
				padding: 0;
			}

			&::after {
        display: block;
        position: absolute;
        bottom: -7px;
        left: 0;
				width: 100%;
        height: 4px;
				margin: 2px auto 0;
        background-color: $key-color;
        transform: scale(0, 1);
			  transform-origin: right top;
				transition: transform 0.2s linear;
				content: '';

				@include mq-sp {
					content: none;
				}
			}

			.mode-pc &:hover::after,
			.mode-pc &.current::after {
				transform-origin: left top;
			  transform: scale(1, 1);
			}
		}
	}
}

/*menu open*/
@include mq-sp {
	.is-menuopen {
		.g-header {
			.g-header__menubtn .lines {
				span {

					&:nth-of-type(1) {
						transform: translateY(11px) rotate(-45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						transform: translateY(-11px) rotate(45deg);
					}
				}
			}

			.g-header__wrap {
				pointer-events: all;
				visibility: visible;
				opacity: 1;
				transition-timing-function: liner;
				transition-duration: 0.4s;
				transition-property: all;
			}
		}
	}
}

/* is-fixed */
.g-header.is-fixed {
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
	// transform: translateY(-55px);

	@include mq-sp {
		// box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
		// transform: translateY(0);

		.g-header__menubtn {
			height: 70px;

			.lines {
				top: 23px;
			}
		}
	}

	.l-wrap {
		height: 90px;

		@include mq-sp {
			height: 70px;
		}
	}
}
