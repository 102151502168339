/*--------------------------------------------------------------------------
   m-pager
---------------------------------------------------------------------------*/
.m-pager {
	display: flex;
	justify-content: center;
	margin-top: 100px;

	@include mq-sp {
		margin-top: 60px;
	}
	
	&__item {
		margin: 0 12px;
		font-size: 16px;

		@include mq-sp {
			margin: 0 10px;
		}

		@include mq-sp-min {
			margin: 0 6px;
		}

		&--new {
			margin-right: 50px;

			@include mq-sp {
				margin-right: 20px;
			}
			
			a {
				padding-right: 15px;

				&::after {
					content: '';
					position: absolute;
					top: calc(50% - 6px);
					right: 0;
					width: 6px;
					height: 15px;
					background: url("../../assets/img/common/ico_arrow_pager.svg") no-repeat;
					background-size: contain;
				}
			}
		}
		
		&--old {
			margin-left: 50px;

			@include mq-sp {
				margin-left: 20px;
			}
			
			a {
				padding-left: 15px;

				&::before {
					content: '';
					position: absolute;
					top: calc(50% - 6px);
					left: 0;
					width: 6px;
					height: 15px;
					background: url("../../assets/img/common/ico_arrow_pager.svg") no-repeat;
					background-size: contain;
					transform: scale(-1, 1);
				}
			}
		}
		
		a {
			position: relative;
			display: block;
			transition: opacity .3s;
			
			.mode-pc &:hover {
				opacity: .7;
			}
		}
		
		span {
			display: block;
		}
	}
}
