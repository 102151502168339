/*--------------------------------------------------------------------------
   m-form
---------------------------------------------------------------------------*/
.m-form {
	margin-top: 220px;

	@include mq-sp {
		margin-top: 80px;
	}

	&__hdg {
		margin-bottom: 100px;
		font-size: 40px;
		font-weight: 200;
		text-align: center;

		@include mq-sp {
			margin-bottom: 50px;
			font-size: 24px;
		}

		&--sub {
			font-size: 28px;

			@include mq-sp {
				font-size: 22px;
			}
		}
	}

	&__lead {
		margin-bottom: 100px;
		line-height: 2.57;
		text-align: center;

		@include mq-sp {
			margin-bottom: 50px;
			font-size: 14px;
			line-height: 2;
			text-align: left;
		}

		& + .m-form__lead {
			margin-top: -60px;

			@include mq-sp {
				margin-top: -30px;
			}
		}

		&--strong {
			color: #F04855;
		}
	}

	.form {
		display: flex;
		flex-wrap: wrap;

		@include mq-sp {
			display: block;
		}

		& + .form {
			margin-top: 80px;
			padding-top: 80px;
			border-top: 1px solid #1E1E1E;

			@include mq-sp {
				margin-top: 40px;
				padding-top: 40px;
			}
		}

		&__hdg {
			width: 215px;
			padding-top: 12px;

			@include mq-sp {
				width: auto;
				padding-top: 0;
				font-size: 14px;
			}

			&:not(:first-of-type) {
				margin-top: 20px;
			}
		}

		&__input {
			width: calc(100% - 215px);

			@include mq-sp {
				width: auto;
				margin-top: 10px;
			}

			&:not(:first-of-type) {
				margin-top: 20px;

				@include mq-sp {
					margin-top: 10px;
				}
			}

			.zip {
				margin-bottom: 15px;

        @include mq-sp {
          margin-bottom: 10px;
				}
			}

			.sp-cap {
				@include mq-sp {
					display: block;
					font-size: 12px;
				}
			}
		}

		&__caption {
			display: block;
			margin-top: 10px;
			font-size: 12px;
			line-height: 2;

			&--wide {
				margin-top: 30px;

				@include mq-sp {
					margin-top: 20px;
				}
			}

      &--zip {
        display: inline-block;
        margin-top: 0;
        padding-left: 10px;

        @include mq-sp {
					display: block;
          margin-top: 5px;
          padding-left: 0;
				}
      }
		}

		.check-list {
			&__item {
				@include mq-sp {
					font-size: 14px;
				}

				&:not(:first-of-type) {
					margin-top: 18px;

					@include mq-sp {
					}
				}
			}
		}

		.date {
			&__combi {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				@include mq-sp {
					display: block;
				}

				&:not(:first-of-type) {
					margin-top: 20px;

					@include mq-sp {
						margin-top: 45px;
					}
				}
			}

			&__item {
				width: 386px;

				@include mq-sp {
					width: auto;
					font-size: 14px;

					& + .date__item {
						margin-top: 10px;
					}
				}
			}

			&__hdg {
				margin-bottom: 10px;

				@include mq-sp {
				}
			}

			&__input {
			}
		}

		&--detail {
			.form__hdg {
				padding-top: 5px;

				@include mq-sp {
					padding-top: 0;
				}

				&:not(:first-of-type) {
					margin-top: 50px;

					@include mq-sp {
						margin-top: 30px;
					}
				}
			}

			.form__input {
				&:not(:first-of-type) {
					margin-top: 50px;

					@include mq-sp {
						margin-top: 10px;
					}
				}
			}
		}

		&--comment {
			.form__hdg {
				padding-top: 8px;
				line-height: 2.57;

				@include mq-sp {
					padding-top: 0;
					line-height: 2;
				}
			}
		}
	}

	.rental {
		margin: 130px 0;

		@include mq-sp {
			margin: 60px 0 40px;
		}

		&__hdg {
			margin-bottom: 18px;
			font-size: 20px;
			font-weight: 700;

			@include mq-sp {
				margin-bottom: 12px;
				font-size: 15px;
			}
		}

		&__list {
			&:last-of-type {
				border-bottom: 1px solid #1E1E1E;
			}
		}

		&__name {
			font-weight: 700;

			a {
				position: relative;
				display: block;
				padding: 25px 0;
        border-top: 1px solid #1E1E1E;
        transition: .2s opacity;

				@include mq-sp {
					padding: 25px 0;
				}

				&::before {
					content: '';
					position: absolute;
					top: calc(50% - 3px);
					right: 42px;
					width: 19px;
					height: 9px;
					background: url("../../assets/img/common/ico_arrow_accordion.svg") no-repeat 96% 50%;
          background-size: contain;
          transition: .4s transform;

					@include mq-sp {
						right: 10px;
						width: 12px;
						height: 6px;
					}
        }

        .mode-pc &:hover {
          opacity: .6;
        }
			}

			&.is-open a::before {
				transform: rotate(180deg);
			}
		}

		&__items {
      display: none;

			@include mq-sp {

      }

      & > ul {
        padding-bottom: 25px;

        @include mq-sp {
          padding: 10px 0 20px;
        }
      }
		}

		.item {
			position: relative;
			display: flex;
			// align-items: center;
			padding-left: 245px;

			@include mq-sp {
				justify-content: space-between;
				min-height: 42px;
				padding: 0;
			}

			&:not(:first-of-type) {
				margin-top: 18px;

				@include mq-sp {
				}
			}

			&__hdg {
				width: calc(100% - 180px);

				@include mq-sp {
					width: calc(100% - 56px);
					font-size: 14px;
				}
			}

			&__price {
				width: 90px;
				padding-right: 20px;
				text-align: right;

				@include mq-sp {
					position: absolute;
					top: 26px;
					right: 0;
					width: 70px;
					padding-right: 0;
					font-size: 14px;
				}
			}

			&__number {
				width: 56px;

				@include mq-sp {
					width: 46px;
					font-size: 14px;
				}
			}
		}

		&__caption {
			margin: 40px 0 0 215px;
			font-size: 12px;
			line-height: 2;

			@include mq-sp {
				margin: 50px 0 0;
			}
		}
	}

	.form-privacy {
		margin-top: 60px;
		padding-left: 215px;

		@include mq-sp {
			padding-left: 0;
		}

		&__txt {
			font-size: 12px;
			line-height: 2;

			@include mq-sp {
			}
		}

		&__cb {
			margin-top: 50px;
			line-height: 2.1;

			@include mq-sp {
				margin-top: 30px;
				font-size: 14px;
				line-height: 1.9;
			}
		}
	}

	.form-btn {
		display: flex;
		justify-content: center;
		margin-top: 80px;

		@include mq-sp {
			margin-top: 50px;
		}

		&__btn {
			width: 420px;

			@include mq-sp {
				max-width: 300px;
				width: 100%;
			}

			input {
				width: 100%;
				height: 60px;
				background-color: #fff;
				border: 1px solid #1E1E1E;
				font-size: 16px;
				transition: .3s;

				@include mq-sp {
					height: 60px;
				}

				.mode-pc &:hover {
					background-color: #1E1E1E;
					color: #fff;
				}
			}
		}
	}

  .form--questionary {
    .form__hdg {
      @include mq-sp {
        margin-bottom: 1em;
      }
    }
    .form__input {
      display: flex;
      justify-content: space-between;

      @include mq-sp {
        display: block;
      }
    }
    .questionary {
      width: 28%;

      @include mq-sp {
        width: 100%;
        margin-bottom: 2em;
      }

      &:last-of-type {
        width: 40%;

        @include mq-sp {
          width: 100%;
          margin-bottom: 0;
        }
      }
    }

    .questionary__hdg {
      margin-bottom: 1em;

      @include mq-sp {
        margin-bottom: 1em;
      }
    }

    .questionary__hdg-sub {
      margin: 2em 0 1em;

      @include mq-sp {
        margin: 2em 0 1em;
      }
    }
  }

	/* 確認 */
	&.m-form--confirm {
		margin-top: 150px;

		@include mq-sp {
			margin-top: 60px;
		}

		.form__hdg {
			width: 230px;
			padding-top: 0;

			@include mq-sp {
				width: auto;
			}
		}

		.form__hdg:not(:first-of-type) {
			@include mq-sp {
				margin-top: 30px;
			}
		}

		.form__input {
			width: calc(100% - 230px);

			@include mq-sp {
				width: auto;
				margin-top: 5px;
				font-size: 14px;
			}
		}

		.form__input:not(:first-of-type) {
			@include mq-sp {
				margin-top: 5px;
			}
		}

		.form--detail {
			.form__hdg {
				padding-top: 0;
			}
		}

		.form--comment {
			.form__hdg {
				padding-top: 0;
			}
		}

		.rental {
			margin-top: 80px;
			padding-top: 80px;
			border-top: 1px solid #1E1E1E;

			@include mq-sp {
				margin-top: 40px;
				padding-top: 40px;
			}

			&__hdg {
				margin-bottom: 30px;
				font-size: 14px;
				font-weight: inherit;
			}

			&__list {
				display: flex;

				@include mq-sp {
					display: block;
				}

				&:last-of-type {
					border-bottom: 0;
				}

				&:not(:first-of-type) {
					margin-top: 30px;

					@include mq-sp {
						margin-top: 25px;
					}
				}
			}

			&__name {
				width: 230px;
				font-weight: 400;

				@include mq-sp {
					width: auto;
					font-size: 14px;
					font-weight: 500;
				}
			}

			&__items {
				display: block;
				width: calc(100% - 230px);
				height: auto;
				opacity: 1;
				visibility: visible;

				@include mq-sp {
					width: auto;
					margin-top: 5px;
				}
			}

			.item {
				min-height: inherit;

				@include mq-sp {
					&:not(:first-of-type) {
						margin-top: 10px;
					}
				}

				&:not(:first-of-type) {
					margin-top: 30px;

					@include mq-sp {
						margin-top: 5px;
					}
				}

				&__hdg {
					width: calc(100% - 56px);
				}

				&__number {
					text-align: center;
				}
			}
		}

		.form-privacy {
			margin-top: 80px;
			padding: 70px 0 0 230px;
			border-top: 1px solid #1E1E1E;

			@include mq-sp {
				margin-top: 40px;
				padding: 40px 0 0;
			}

			&__txt {
				font-size: 16px;
				line-height: 2.25;

				@include mq-sp {
					font-size: 14px;
					line-height: 2;
				}
			}
		}

		.form-btn {
			flex-direction: row-reverse;

			@include mq-sp {
				display: block;
				margin-top: 70px;
			}

			&__btn {
				width: 320px;
				margin: 0 10px;

				@include mq-sp {
					max-width: 300px;
					width: 100%;
					margin: 0 auto 15px;
				}
			}
		}
	}
}
