/*--------------------------------------------------------------------------
   .g-footer
---------------------------------------------------------------------------*/
.g-footer {
	padding: 60px 0 65px;

  @include mq-sp {
		padding: 60px 0 30px;
		background-color: #A3A3A3;
  }

	&__inner {
		
		&.l-wrap {
			width: auto;
			padding: 0 50px;
			
			@include mq-sp {
				padding: 0 35px;
			}
		}
	}

	&__nav {
		position: absolute;
		top: 10px;
		right: 50px;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		@include mq-sp {
			display: none;
		}
	}

	&__nav .nav {
		display: flex;

		&__item {
			margin-left: 15px;
			font-family: $font-family-en;
      font-size: 17px;

			a {
				display: block;
				position: relative;
				padding: 0 2px;

				&::after {
					display: block;
					position: absolute;
					bottom: -7px;
					left: 0;
					width: 100%;
					height: 2px;
					margin: 2px auto 0;
					background-color: $key-color;
					transform: scale(0, 1);
					transform-origin: right top;
					transition: transform 0.2s linear;
					content: '';
				}

				.mode-pc &:hover::after,
				.mode-pc &.current::after {
					transform-origin: left top;
					transform: scale(1, 1);
				}
			}
		}
	}

	&__nav .sns-nav {
		display: flex;
		align-items: center;
		margin-left: 30px;

		&__item {
			width: 22px;
			height: 22px;
			margin-left: 12px;
			line-height: 1;

			a {
				display: block;
				transition: .2s;

        .mode-pc &:hover img {
          animation: t_bounce 2s ease-in-out;

          @keyframes t_bounce {
            5%  { transform: scale(1.2, .8); }
            10% { transform: scale(.8, 1.2) translateY(-2px); }
            15% { transform: scale(1, 1); }
          }
        }
			}

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	&__logo {
		line-height: 1;

		@include mq-sp {

			img {
				width: 152px;
			}
		}

		a {
			display: block;
		}
	}

	&__info {
		@include mq-sp {
			position: relative;
			margin-top: 20px;
			font-size: 15px;
			color: #fff;

			.info {
				&__hdg {
					font-size: 16px;
					font-weight: 700;
				}

				&__add {
					margin-top: 10px;
					font-size: 14px;
				}

				&__tel {
					margin-top: 15px;
					font-size: 20px;
					font-weight: 700;

					span {
						margin-right: 5px;
						font-size: 16px;
					}
				}
			}

			.sns {
				display: flex;
				position: absolute;
				right: 0;
				bottom: 3px;

				&__item {
					width: 26px;
					height: 26px;
					margin-left: 16px;
					line-height: 1;

					a {
						display: block;
					}

					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}

	&__bottom {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		justify-content: space-between;
		margin-top: 38px;
		padding-top: 25px;
		border-top: 1px solid $key-color;

		@include mq-sp {
			display: block;
			margin-top: 18px;
			padding-top: 19px;
			border-top: 1px solid #fff;
		}

		.group-link {
			display: flex;
			align-items: flex-end;

			@include mq-sp {
				justify-content: flex-end;
			}

			&__item {
				margin-left: 30px;

				@include mq-sp {
					margin-left: 20px;

					&--tenjin img {
						width: 95px;
					}

					&--500g {
						padding-bottom: 5px;

						img {
							width: 89px;
						}
					}
				}
			}
		}
	}

	&__copy {
		margin-top: 25px;
		font-size: 10px;

		@include mq-sp {
			margin-top: 20px;
			font-size: 11px;
			color: #fff;
		}
	}
}
