@charset 'UTF-8';
@import '../_import.scss';

/*--------------------------------------------------------------------------
   form
---------------------------------------------------------------------------*/
input,
button,
select,
textarea {
	border-radius: 0;
	-ms-appearance: none;
	appearance: none;
}

form input,
form textarea {
	border: 0;
}

input[type=checkbox],
input[type=radio] {
	position: absolute;
	opacity: 0;
}

.c-input {
	width: 100%;
	height: 40px;
	padding: 0 20px;
  box-sizing: border-box;
	background-color: #EFEFEF;
	border-radius: 6px;
	font-size: 14px;

	@include mq-sp {
		height: 40px;
		padding: 5px 15px;
		font-size: 13px;
  }

  &--time {
    width: 120px;

    @include mq-sp {
      width: 90px;
    }
  }
}

.c-textarea {
	width: 100%;
	max-height: 345px;
	padding: 10px 15px;
	box-sizing: border-box;
	background-color: #EFEFEF;
	border-radius: 6px;
	overflow: auto;

	@include mq-sp {
		max-height: 300px;
		padding: 10px 12px;
	}
}

/* checkbox */
.c-checkbox {
	visibility: hidden;
	position: absolute;
}
.c-checkbox + label {
	display: inline-block;
	box-sizing: border-box;
	position: relative;
	padding-left: 38px;
	vertical-align: middle;
	cursor: pointer;
	font-weight: 700;

	@include mq-sp {
		padding-left: 28px;
	}
}
.c-checkbox + label::before {
	display: block;
	position: absolute;
	top: 1px;
	left: 0;
	width: 24px;
	height: 24px;
	border: 1px solid #1E1E1E;
	border-radius: 4px;
	background: #fff;
	content: '';

	@include mq-sp {
		top: 0;
		width: 20px;
		height: 20px;
	}
}
.c-checkbox + label::after {
	display: block;
	position: absolute;
	top: 3px;
	left: 7px;
	width: 10px;
	height: 18px;
	border-right: 4px solid #1E1E1E;
	border-bottom: 4px solid #1E1E1E;
	content: '';
	transform: rotate(45deg);
	opacity: 0;

	@include mq-sp {
		top: 2px;
		left: 6px;
		width: 8px;
		height: 14px;
	}
}
.c-checkbox:checked + label::after {
	opacity: 1;
}

/* select */
.c-select {
	position: relative;
	width: 56px;
	height: 24px;
  box-sizing: border-box;
	border-radius: 6px;
	border: 1px solid #707070;
	background-color: #fff;
	line-height: 1;

	@include mq-sp {
		width: 46px;
	}
}
.c-select::after {
	position: absolute;
	top: calc(50% - 2px);
	right: 8px;
	width: 14px;
	height: 6px;
	background: url("../../assets/img/common/ico_arrow_accordion.svg") no-repeat;
	background-size: 14px 6px;
	content: '';
	pointer-events: none;

	@include mq-sp {
		top: calc(50% - 1px);
		right: 4px;
		width: 12px;
		height: 4px;
		background: url("../../assets/img/common/ico_arrow_accordion.svg") no-repeat;
		background-size: 12px 4px;
	}
}
.c-select select {
	padding-left: 10px;
	width: 100%;
	height: 100%;
	font-weight: 500;
	vertical-align: inherit;
	cursor: pointer;

	@include mq-sp {
		padding-left: 8px;
	}
}
