/*--------------------------------------------------------------------------
   m-privacy
---------------------------------------------------------------------------*/
.m-privacy {
	margin-top: 190px;

	@include mq-sp {
		margin-top: 80px;
	}
	
	&__note {
		display: flex;

		@include mq-sp {
			display: block;
		}

		&:not(:first-of-type) {
			margin-top: 70px;

			@include mq-sp {
				margin-top: 40px;
			}
		}
	}
	
	&__hdg {
		width: 215px;
		margin-top: -4px;
		font-size: 16px;
		font-weight: 400;
		line-height: 2.25;

		@include mq-sp {
			width: auto;
			font-size: 14px;
		}
	}
	
	&__body {
		width: calc(100% - 215px);

		@include mq-sp {
			width: auto;
		}
	}
	
	&__txt {
		font-size: 12px;
		line-height: 2;

		@include mq-sp {
			font-size: 11px;
			line-height: 1.9;
		}
		
		dt {
			margin-top: 1.8em;

			@include mq-sp {
				margin-top: 1.4em;
			}
		}
		
		li {
			margin-left: .55em;
			text-indent: -.55em;
		}
		
		&--strong {
			color: #F04855;
		}
	}
}
