/*--------------------------------------------------------------------------
   l-page
---------------------------------------------------------------------------*/
.l-page-wrapper {
  overflow: hidden;
  visibility: hidden;

	@include mq-sp {
		width: 100%;
		height: 100%;
	}
}

.is-loading .l-page-wrapper {
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.load-complete .l-page-wrapper {
  visibility: visible;
}

.l-page {
	@include mq-sp {
		overflow-x: hidden;
		// overflow-y: auto;
		width: 100%;
		height: 100%;
		-webkit-overflow-scrolling: touch;
	}

	&__main {
		padding: 122px 0 100px;

		@include mq-sp {
			padding: 90px 0;
		}
	}
}

@include mq-sp {
	.is-loading .l-page {
		overflow-y: hidden;
	}
}
