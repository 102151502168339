/**
 *
 *  LOADER
 *
 */

/*--------------------------------------------------------------------------
   loader
---------------------------------------------------------------------------*/
#Loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 1);
  z-index: 99999;
}
#Loader #progress {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 0px;
  height: 3px;
  background: $key-color;
}

/* trans
-------------------------------------------*/
#Loader {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

/* anima
-------------------------------------------*/
.load-complete #Loader {
  opacity: 0;
}
