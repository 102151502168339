/*--------------------------------------------------------------------------
   c-hdg
---------------------------------------------------------------------------*/
.c-hdg-page {
	position: relative;
	margin: 100px 0;
	font-family: $font-family-en;
	font-size: 48px;
	font-weight: 300;
	text-align: center;

	@include mq-sp {
		margin: 70px 0;
		font-size: 34px;
	}
}
