/*--------------------------------------------------------------------------
   c-btn
---------------------------------------------------------------------------*/
.c-btn-basic {
	width: 310px;
	height: 60px;
	margin: 0 auto;
	font-family: $font-family-en;
	font-size: 18px;
	
	@include mq-sp {
		max-width: 310px;
		width: 100%;
	}

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 60px;
		background-color: #fff;
		border: 1px solid $key-color;
		transition: .3s;
		
		.mode-pc &:hover {
			background-color: $key-color;
			color: #fff;
		}
	}
}
