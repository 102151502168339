/*--------------------------------------------------------------------------
   init
---------------------------------------------------------------------------*/
// init
.is-animate {
  &.is-fadein {
    opacity: 0;
  }

  &.is-fadeup {
    opacity: 0;
    transform: translateY(80px);

    @include mq-sp {
			transform: translateY(50px);
		}
  }
}


/*--------------------------------------------------------------------------
   animated
---------------------------------------------------------------------------*/
.is-animated {

  &.is-fadein {
    opacity: 1;
    transition: opacity .6s ease-in-out;
    transition-delay: 0s;
  }

  &.is-fadeup {
    opacity: 1;
    transform: translateY(0);
    transition: opacity .8s ease-in-out, transform .8s $ease-out-circ;
    transition-delay: 0s;
  }
}
