/*--------------------------------------------------------------------------
   html
---------------------------------------------------------------------------*/
html {
  // -ms-overflow-style: none;

  &.is-menuopen {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  @include mq-sp {
    width: 100%;
    height: 100%;
  }
}



/*--------------------------------------------------------------------------
   head
---------------------------------------------------------------------------*/
head {
	font-family: 'pc';
}

/* media query -> sp
=================================================================*/
@media #{$bp-sp} {
	head {
		font-family: 'sp';
	}
}


/*--------------------------------------------------------------------------
   body
---------------------------------------------------------------------------*/
body {
	min-width: 1200px;
	color: $font-color-basic;
	font-family: $font-family;
	font-size: $font-size-pc;
	font-weight: $font-weight-win;
	line-height: $line-height;
	letter-spacing: $letter-spacing;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

  @include mq-sp {
    width: 100%;
		min-width: $min-width-sp;
		height: auto;
		font-size: $font-size-sp;
		line-height: $line-height-sp;
  }
}

.os-mac body{
	font-weight: $font-weight-mac;
}

/*--------------------------------------------------------------------------
   a
---------------------------------------------------------------------------*/
a {
	outline: none;
	color: $link-color;
	-webkit-tap-highlight-color: $link-tap-highlight-color;

	&:link,
	&:visited {
		text-decoration: $link-text-decoration;
	}
}


/*--------------------------------------------------------------------------
   reset
---------------------------------------------------------------------------*/
*{
	word-wrap: break-word;
	outline: none;
}

img{
	vertical-align: top;
	height: auto;
}
ul,ol {
	list-style: none;
}


/* Placeholder
-----------------------------------------------*/
::-webkit-input-placeholder {
	color: #B4B4B4;
}

::-moz-placeholder {
	color: #B4B4B4;
}

:-ms-input-placeholder {
	color: #B4B4B4;
}

:-moz-placeholder {
	color: #B4B4B4;
}


/*--------------------------------------------------------------------------
   selection
---------------------------------------------------------------------------*/
::-moz-selection {
	background-color: $selection-background-color;
	color: $selection-color;
	text-shadow: $selection-text-shadow;
}

::selection {
	background-color: $selection-background-color;
	color: $selection-color;
	text-shadow: $selection-text-shadow;
}
