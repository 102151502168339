/*--------------------------------------------------------------------------
   m-studio-nav
---------------------------------------------------------------------------*/
.m-studio-nav {
	display: flex;
	justify-content: center;

	&__btn {
		position: relative;
		margin: 0 50px;
		z-index: 0;

		@include mq-sp {
			margin: 0 9px;
		}

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 276px;
			height: 276px;
			padding-top: 25px;

			@include mq-sp {
				width: 162px;
				height: 162px;
				padding-top: 8px;
			}

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 276px;
				height: 276px;
				border-radius: 50%;
				border: 70px solid #EFEFEF;
				z-index: -1;
				transition: .3s;

				@include mq-sp {
					width: 162px;
					height: 162px;
					border: 44px solid #EFEFEF;
				}
			}

			.mode-pc &:hover::before {
				border: 70px solid #d4d4d4;
			}
		}

    span {
      display: block;
      padding-top: 20px;
      text-align: center;
      color: #999;
      font-size: 12px;

      @include mq-sp {
        padding-top: 15px;
        font-size: 9px;
      }
    }

		&--1st img {
			width: 150px;

			@include mq-sp {
				width: 98px;
			}
		}

		&--2nd img {
			width: 173px;

			@include mq-sp {
				width: 112px;
			}
		}
	}
}
